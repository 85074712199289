import { Role } from '@ss/typings/nta-hunter';
import { instanceToPlain } from 'class-transformer';
import { FieldValue } from 'firebase/firestore';

const TEMP_DEFAULT = 100;

export class UserNotificationSetting {
	userId?: string;

	type? = '';

	emailEnabled? = false;

	webEnabled? = true;

	description? = '';

	timing?: 'weekly' | 'monthly' | 'never';

	enterpriseOnly? = false;

	title?: string;

	constructor(partial: Partial<UserNotificationSetting>) {
		Object.assign(this, partial);
	}
}

export class MyUserInfo {
	currentFacilityId: string;

	facilityIds: string[];

	proxyFacilityId: string;

	enterpriseId: string;

	enterpriseIds: string[];

	userId: string;

	facilities: Array<Partial<Facility>>;

	facility: Partial<Facility>;

	email: string;

	phoneNumber: string;

	name: string;

	isSuperAdmin = false;

	corpName: string;

	corporationId: string;

	recentNtaPdfs: string[] = [];

	hasTempPassword = false;

	mfaEnabled = false;

	createdBy?: string;

	createdDate?: Date | FieldValue;

	notificationSettings: UserNotificationSetting[];

	constructor(partial: Partial<MyUserInfo>) {
		Object.assign(this, partial);
	}
}

export class FacilityLogItem {
	facilityId:string;

	logId:string;

	status = 'pass';

	failed = false;

	entryDateTime:Date;

	fullName:string;

	note:string;

	questions:FacilityQuestion[];

	screener:string;

	screenerSignature: string;

	screenerSigUrl:string;

	screenerSigFullPath:string;

	signoutDateTime: Date;

	temp:number;

	temp1:number;

	temp1DateTime: Date;

	temp2:number;

	temp2DateTime: Date;

	type: string;

	signature:string;

	sigUrl:string;

	sigFullPath:string;

	checkoutDateTime:string;

	signatureExport = 'Signature not required';

	constructor(partial:Partial<FacilityLogItem>) {
		Object.assign(this, partial);

		if (this.sigUrl) {
			this.signatureExport = `Signed by ${this.fullName}`;
		}

		['entryDateTime', 'temp1DateTime', 'temp2DateTime'].filter((key) => this[key] && this[key].toDate).forEach((key) => this[key] = this[key].toDate());
	}
}

export const QuestionConvertor = {
	toFirestore: (q: FacilityQuestion, index?: number) => ({
		questionId: index ?? q.questionId,
		description: q.description ?? '',
		descriptionSpanish: q.descriptionSpanish ?? '',
		text: q.text ?? '',
		textSpanish: q.textSpanish ?? '',
		selectedAnswer: q.selectedAnswer ?? '',
		exportHeader: q.exportHeader ?? '',
		sort: index ?? q.sort,
		disabledTypes: q.disabledTypes ?? [],
		type: q.type ?? 'multi',
		options: q.options ?? []
	})
};

export const logConvertor = {
	toFirestore: (logItem: FacilityLogItem) => {
		const { questions, ...restOfLog } = logItem;
		return {
			questions: questions.map((q) => QuestionConvertor.toFirestore(q)),
			...restOfLog
		};
	},
	fromFirestore: (snapshot, options) => {
		const data = snapshot.data(options);
		return new FacilityLogItem(data);
	}
};

export class FacilityUser {
	userId: string;

	isAdmin: boolean;

	displayName: string;

	email: string;

	phoneNumber: string;

	canAccessLog: boolean;

	canManageUsers: boolean;

	canEditFacility: boolean;

	canManageQuestions: boolean;

	receiveEmailAlerts: boolean;

	receiveTextAlerts: boolean;

}

export class NtaUser {
	userId: string;

	email: string;

	name: string;

	role: Role;
}

export class FacilityEntrantType {
	enabled = true;

	requireScreener = false;

	requireScreenerSignature = false;

	requireTemp = true;

	enableSecondTemp = false;

	requireSignature = false;

	enableCheckout = false;

	requireEntryTemp = true; // Hide the temperatures completely for a given entrant type

	enableLabelPrint = false;

	name: string;

	spanish: string;

	editing = false;

	constructor(partial:Partial<FacilityEntrantType>) {
		Object.assign(this, partial);

		if (!this.enabled) {
			Object.keys(this).forEach((key) => {
				if (typeof this[key] === 'boolean') {
					this[key] = false;
				}
			});
		}
	}
}

export class FacilityQuestionOption {
	text: string;

	spanish: string;

	isFailure: boolean;
}

export class FacilityQuestion {

	questionId: number;

	description: string;

	descriptionSpanish: string;

	text: string;

	textSpanish: string;

	selectedAnswer: string;

	exportHeader: string;

	sort: number;

	disabledTypes: string[] = [];

	type: 'free' | 'multi' = 'multi';

	options: FacilityQuestionOption[];

	constructor(partial:Partial<FacilityQuestion>) {
		Object.assign(this, partial);

		if (!this.options) {
			this.options = [
				{ isFailure: true, text: '', spanish: '' },
				{ isFailure: false, text: '', spanish: '' }
			];
		}
	}
}

export class Corporation {
	ntaUsers: Record<string, NtaUser> = {};

	enterpriseIds: string[] = [];

	corporationId: string;

	constructor(partial:Partial<Corporation>) {
		Object.assign(this, partial);
	}
}

export class Enterprise {

	enterpriseId: string;

	name?: string;

	facilityIds: string[] = [];

	ntaUsers: Record<string, NtaUser> = {};

	fakeStats = false;

	isCorporate = false;

	facilityType: string;

	constructor(partial: Partial<Enterprise>) {
		Object.assign(this, partial);
	}
}

export class Facility {
	billingActive = true;

	enableBTE = false;

	enableSpanish = false;

	facilityPin:number;

	playAudio = true;

	hardwareSubscriber = false;

	textBetaUser = false;

	textNotificationsEnabled = false;

	enableTouchless = false;

	enableThermQrCode = true;

	name:string;

	enterpriseId: string;

	patientCount: number;

	monthlyPatientCount: number;

	createdDate: Date;

	feverTemp = TEMP_DEFAULT;

	facilityId: string;

	facilityType: string;

	accessCode:string;

	enableQR = false;

	ntaEnabled = false;

	ntaEnabledYN?: 'Yes' | 'No' = 'No';

	stripe:{
		customer:string;

		subscription?: string;
	};

	enableThermOnEntry = true;

	successMessage = 'You\'re awesome! Thanks and have a great day!';

	successMessageSpanish = 'Estamos muy contentos de que estés aquí. ¡Bienvenidos!';

	failedMessage = 'Thank you! It looks like we need to do some additional screening, please call the front desk.';

	failedMessageSpanish = 'No continúe, parece que puede ser necesario realizar un examen adicional. Por favor llame a la recepción.';

	printMessage = 'Click close to print your nametag.';

	printMessageSpanish = 'Cerrar para imprimir etiqueta de nombre.';

	users:FacilityUser[];

	ntaUsers:NtaUser[] = [];

	entrantTypes:FacilityEntrantType[] = [];

	questions:FacilityQuestion[];

	currentUserIsAdmin = false;

	features: string[] = [];

	get ntaUserNames() {
		return this.ntaUsers.slice().sort((a, b) => {
			if (a.role === 'admin' && b.role === 'screener') {
				return 1;
			} else if (a.role === 'screener' && b.role === 'admin') {
				return -1;
			}
			return a.name.localeCompare(b.name);

		}).map((user) => user.name);
	}

	constructor(partial:Partial<Facility>, initAll = true) {
		Object.assign(this, partial);

		if (initAll) {

			if (!Array.isArray(this.users)) {
				this.users = Object.keys(this.users as any ?? {}).map((userId) => ({
					userId,
					...this.users[userId]
				}));
			}

			if (!Array.isArray(this.ntaUsers)) {
				this.ntaUsers = Object.keys(this.ntaUsers as any ?? {}).map((userId) => ({
					userId,
					...this.ntaUsers[userId]
				}));
			}

			this.textNotificationsEnabled = this.textBetaUser || this.hardwareSubscriber;

			this.questions = this.questions?.map((question) => new FacilityQuestion(question)) ?? [];

			this.entrantTypes = this.entrantTypes?.map((type) => new FacilityEntrantType(type)) ?? [];
		}

		this.ntaEnabledYN = this.ntaEnabled ? 'Yes' : 'No';
	}
}

export const FacilityConvertor = {
	toFirestore: (facility: Facility) => {
		const { questions, entrantTypes, ...restOfFacility } = facility;
		const ret = {
			questions: questions.map((q) => QuestionConvertor.toFirestore(q)),
			entrantTypes: entrantTypes.map((et) => instanceToPlain(et)),
			...restOfFacility
		};
		return ret;
	},
	fromFirestore: (snapshot, options) => {
		const data = snapshot.data(options);
		return new Facility(data);
	}
};
