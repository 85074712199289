export const firebaseConfig = {
	apiKey: 'AIzaSyDlBG6KpDmYlwUQRCKLjBxQgAHV4lJ91Ro',
	authDomain: 'simpliscreen.firebaseapp.com',
	databaseURL: 'https://simpliscreen.firebaseio.com',
	projectId: 'simpliscreen',
	storageBucket: 'simpliscreen.appspot.com',
	messagingSenderId: '989583300583',
	appId: '1:989583300583:web:c552e164422fea6b8bcf42',
	functionsUrl: 'https://us-central1-simpliscreen.cloudfunctions.net'
};

export const stripeKey = 'pk_test_51H7TjnAo1uWgOtBBz9GkVqh5813NuNsSABFObpeauzHPnup2hMicxEn3hxMFssSsjDkXtemWgx638qEvyYaJo7mh00tZyf0dxH';

export const env: 'prod' | 'beta' | 'test' = 'test';
