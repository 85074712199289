import { Injectable } from '@angular/core';
import {
	getDownloadURL,
	Storage,
	ref as storageRef,
	uploadString,
} from '@angular/fire/storage';
import {
	BehaviorSubject,
	forkJoin,
	from,
	Observable,
	of,
	Subject,
	Subscription,
} from 'rxjs';
import { debounceTime, filter, first, map, switchMap, tap } from 'rxjs/operators';
import {
	Facility,
	FacilityConvertor,
	FacilityLogItem,
	FacilityQuestion,
	FacilityUser,
	logConvertor,
	QuestionConvertor,
} from '@ss/typings/facility';
import { addDays, format, parseISO, startOfDay } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from 'es-toolkit';
import { MatTableDataSource } from '@angular/material/table';
import { UserStore } from './user.store';
import { MenuItem } from '@ss/simpliscreen/settings-menu';
import { SnackbarService } from '@ss/shared/snackbar';
import {
	addDoc,
	collection,
	CollectionReference,
	deleteDoc,
	deleteField,
	doc,
	docData,
	DocumentReference,
	Firestore,
	onSnapshot,
	orderBy,
	query,
	QuerySnapshot,
	setDoc,
	updateDoc,
	where
} from '@angular/fire/firestore';

// TODO split this into a global facility store, a local FrontDeskStore, and a local FacilitySettingsStore
@Injectable({ providedIn: 'root' })
export class FacilityStore {
	facility: Facility = new Facility({}, false);

	currentDayLogs: FacilityLogItem[] = [];

	nameFilter = '';

	userId: string;

	isSuperAdmin = false;

	screeningLogStartDate = addDays(new Date(), -1);

	screeningLogEndDate = new Date();

	screeningLogSourceData: FacilityLogItem[] = [];

	screeningLogSource: MatTableDataSource<FacilityLogItem> = new MatTableDataSource();

	screeningLogSub: Subscription;

	updateFacility$ = new Subject();

	taskType: 'questionnaire' | 'temp' | 'checkout';

	private loaded$: BehaviorSubject<Facility> = new BehaviorSubject(null);

	get activeEntrantTypes() {
		return this.facility?.entrantTypes.filter((type) => type.enabled);
	}

	get facilityQrCodeUrl() {
		return `https://api.qrserver.com/v1/create-qr-code/?data=https://app.simpliscreen.com/?accessCode=${this.facility.accessCode}&color=1470af&size=125x125`;
	}

	get logsNeedingSecondTemp() {
		const typesNeedingSecondTemp = this.facility.entrantTypes
			.filter((entrantType) => entrantType.enableSecondTemp)
			.map((entrantType) => entrantType.name);
		return this.currentDayLogs.filter((log) => typesNeedingSecondTemp.includes(log.type) && !log.temp2DateTime);
	}

	get logsNeedingCheckout() {
		const typesNeedingCheckout = this.facility.entrantTypes
			.filter((entrantType) => entrantType.enableCheckout)
			.map((entrantType) => entrantType.name);
		return this.currentDayLogs.filter((log) => typesNeedingCheckout.includes(log.type) && !log.checkoutDateTime);
	}

	get logsForCurrentTaskType() {
		return this.taskType === 'temp' ? this.logsNeedingSecondTemp : this.logsNeedingCheckout;
	}

	get filteredLogsForCurrentTaskType() {
		return this.logsForCurrentTaskType.filter((log) => log.fullName.toLowerCase().includes(this.nameFilter.toLowerCase()));
	}

	get entrantTypesWithColors() {
		const colors = ['#004E80', '#26a69a', '#F57C05', '#22E9B6', '#599ee1'];
		return this.activeEntrantTypes.reduce((coloredTypes, et, i) => {
			if (et.enabled) {
				coloredTypes[et.name] = colors[i];
			}
			return coloredTypes;
		}, {});
	}

	get logCounts() {
		const failed = this.screeningLogSourceData.reduce(
			(sum, log) => log.failed ? sum + 1 : sum,
			0
		);
		return this.screeningLogSourceData.length ? `${failed} Failed of ${this.screeningLogSourceData.length}` : '';
	}

	get currentUserFromFacility() {
		return this.facility?.users?.find((u) => u.userId === this.userId);
	}

	get canAccessLog() {
		return this.hasAccess('canAccessLog');
	}

	get canManageUsers() {
		return this.hasAccess('canManageUsers');
	}

	get canManageQuestions() {
		return this.hasAccess('canManageQuestions');
	}

	get canEditFacility() {
		return this.hasAccess('canEditFacility');
	}

	get hasAnyFacilitySettingsAccess() {
		return (
			this.canManageUsers ||
			this.canManageQuestions ||
			this.canEditFacility
		);
	}

	get settingsMenu(): MenuItem[] {
		let menu = [];
		if (this.canManageUsers) {
			menu.push({
				fontIcon: 'people',
				link: 'users',
				title: 'Users',
			});
		}
		if (this.canManageQuestions) {
			menu.push({
				fontIcon: 'fa-question',
				link: 'questions',
				fontSet: 'fas',
				title: 'Questions',
			});
		}
		if (this.canEditFacility) {
			menu = [
				{
					fontIcon: 'fa-store-alt',
					link: 'facility',
					fontSet: 'fas',
					title: 'Facility',
				},
				{
					fontIcon: 'fa-id-card',
					link: 'entrant-types',
					fontSet: 'far',
					title: 'Entrant Types',
				},
				...menu,
			];
		}

		return menu;
	}

	constructor(
		private storage: Storage,
		private transloco: TranslocoService,
		private user: UserStore,
		private snackbar: SnackbarService,
		private firestore: Firestore
	) {
		this.user.loaded
			.pipe(
				filter((u) => !!u.userId),
				map((u) => {
					this.userId = u.userId;
					this.isSuperAdmin = u.isSuperAdmin;
					return u.proxyFacilityId || u.currentFacilityId;
				}),
				switchMap((facilityId) => docData<Facility>(doc(this.firestore, '/facilities', facilityId) as DocumentReference<Facility>, { idField: 'facilityId' })),
				filter((f) => !!f),
				switchMap((facility: Facility) => {
					this.translateFacility(facility);
					// TODO this facility is not actually observable!! Need to fix this
					this.facility = new Facility(facility);
					const facilityLogCollection: CollectionReference<FacilityLogItem> = collection(firestore, `facilities/${this.facility.facilityId}/logs`) as CollectionReference<FacilityLogItem>;
					const q = query(
						facilityLogCollection,
						where('entryDateTime', '>=', startOfDay(this.screeningLogStartDate)),
						where('entryDateTime', '<=', addDays(this.screeningLogEndDate, 1)),
						orderBy('entryDateTime')
					);
					return new Observable<QuerySnapshot>((subscriber) => onSnapshot(q, subscriber));
				}),
				map((qs:QuerySnapshot) => qs.docs.map((d) => new FacilityLogItem({ logId: d.id, ...d.data() })))
			)
			.subscribe((logs: FacilityLogItem[]) => {
				this.currentDayLogs = logs;
				this.loaded$.next(this.facility);
				this.updateScreeningLog();
			});

		const DEBOUNCE_INTERVAL = 2000;
		this.updateFacility$
			.asObservable()
			.pipe(debounceTime(DEBOUNCE_INTERVAL))
			.pipe(switchMap(() => {
				// const restOfFacility = Object.keys(this.facility).reduce(
				// 	(obj, key) => {
				// 		if (key !== 'users' && !isNil(this.facility[key])) {
				// 			obj[key] = this.facility[key];
				// 		}
				// 		return obj;
				// 	},
				// 	{}
				// );
				// console.log(restOfFacility);
				const ref = doc(this.firestore, `facilities`, this.facility.facilityId).withConverter(FacilityConvertor);
				return setDoc(ref, new Facility(this.facility));
			}))
			.subscribe(() => this.snackbar.success('Facility Changes Saved'));
	}

	addLogEntry(logEntry: FacilityLogItem, now: Date) {
		if (!this.facility.stripe) {
			this.facility.stripe = { customer: this.facility.facilityId };
		}

		let docId;

		const newLog = new FacilityLogItem({
			fullName: logEntry.fullName,
			screener: logEntry.screener,
			type: logEntry.type,
			temp1: logEntry.temp,
			questions: logEntry.questions,
			temp1DateTime: logEntry.temp ? now : null,
			entryDateTime: now,
		});

		Object.keys(newLog).forEach((key) => isNil(newLog[key]) && delete newLog[key]);

		return from(addDoc(collection(this.firestore, `facilities/${this.facility.facilityId}/logs`).withConverter(logConvertor), newLog)).pipe(
			switchMap((docRef) => {
				docId = docRef.id;

				const subs = {
					sigFullPath: this.uploadSignatureImage(
						logEntry.signature,
						`facility-logs/${this.facility.stripe.customer}/${docRef.id}/entrantSignature.png`
					),
					screenerSigFullPath: this.uploadSignatureImage(
						logEntry.screenerSignature,
						`facility-logs/${this.facility.stripe.customer}/${docRef.id}/entrantSignature.png`
					),
				};
				return forkJoin(subs);
			}),
			switchMap(({ sigFullPath, screenerSigFullPath }) => updateDoc(
				doc(
					this.firestore,
					`facilities/${this.facility.facilityId}/logs/${docId}`
				).withConverter(logConvertor),
				{
					sigFullPath,
					screenerSigFullPath,
					logId: docId,
				}
			))
		);
	}

	updateLogEntry(
		logEntry: FacilityLogItem,
		what: 'checkout' | 'all' | keyof FacilityLogItem
	) {
		const now = new Date();
		let updates = {};
		switch (what) {
			case 'temp2':
				updates = {
					temp2: logEntry.temp2,
					temp2DateTime: now,
				};
				break;
			case 'checkout':
				updates = { checkoutDateTime: now };
				break;
			case 'all':
				updates = logEntry;
				break;
			default:
				updates[what] = logEntry[what];
		}
		return from(updateDoc(doc(this.firestore, `facility-logs/${this.facility.facilityId}/logs/${logEntry.logId}`).withConverter(logConvertor), updates));
	}

	updateSignature(
		logEntry: FacilityLogItem,
		signature: string,
		type: 'screener' | 'entrant'
	) {
		const filePath = `facility-logs/${this.facility.stripe.customer}/${logEntry.logId}/${type}Signature.png`;
		this.uploadSignatureImage(signature, filePath).subscribe((sigUrl: string) => {
			if (type === 'screener') {
				this.updateLogEntry(logEntry, 'screenerSigUrl');
			} else {
				this.updateLogEntry(logEntry, 'sigUrl');
			}
		});
	}

	updateScreeningLog() {
		if (!this.screeningLogEndDate || !this.screeningLogStartDate) {
			return;
		}
		if (this.screeningLogSub) {
			this.screeningLogSub.unsubscribe();
		}
		const facilityLogCollection: CollectionReference<FacilityLogItem> = collection(this.firestore, `facilities/${this.facility.facilityId}/logs`) as CollectionReference<FacilityLogItem>;
		const q = query(
			facilityLogCollection,
			where('entryDateTime', '>=', startOfDay(this.screeningLogStartDate)),
			where('entryDateTime', '<=', addDays(this.screeningLogEndDate, 1)),
			orderBy('entryDateTime')
		);
		// return new Observable<QuerySnapshot>((subscriber) => onSnapshot(q, subscriber));
		this.screeningLogSub = new Observable<QuerySnapshot>((subscriber) => onSnapshot(q, subscriber))
			.pipe(map((qs:QuerySnapshot) => qs.docs.map((d) => new FacilityLogItem({ logId: d.id, ...d.data() }))))
			.subscribe((logs: FacilityLogItem[]) => {
				this.screeningLogSource.data = logs;
				this.screeningLogSourceData = logs;
			});
	}

	updateEntrantTypes() {
		updateDoc(doc(this.firestore, `facilities/${this.facility.facilityId}`).withConverter(FacilityConvertor), {
			entrantTypes: this.facility.entrantTypes.map((type) => {
				const { editing, ...restOfType } = type;
				return restOfType;
			})
		}).then(() => this.snackbar.success('Changes Saved'));
	}

	deleteLogEntry(log: FacilityLogItem) {
		deleteDoc(doc(this.firestore, `facility/${this.facility.facilityId}/logs/${log.logId}`));
	}

	saveQuestions() {
		const questions = this.facility.questions.map((question, index) => QuestionConvertor.toFirestore(question, index));
		return from(updateDoc(doc(this.firestore, `facilities/${this.facility.facilityId}`).withConverter(FacilityConvertor), { questions }));
	}

	hasAccess(type: keyof FacilityUser) {
		return (
			this.currentUserFromFacility?.isAdmin ||
			this.currentUserFromFacility?.[type] ||
			this.isSuperAdmin
		);
	}

	load() {
		return this.loaded$
			.asObservable()
			.pipe(filter((facility) => !!facility));
	}

	translateFacility(facility: Facility) {
		// TODO could we create translations dynamically in a firebase function?
		facility.entrantTypes.forEach((entrantType) => {
			// TODO this should probably use ids for the entrant types when we switch to firestore
			this.transloco.setTranslationKey(
				entrantType.name,
				entrantType.name,
				'en'
			);
			this.transloco.setTranslationKey(
				entrantType.name,
				entrantType.spanish,
				'es'
			);
		});

		facility.questions.forEach((question, index) => {
			// TODO this should use the id from firestore when we switch
			question.questionId = index;
			this.transloco.setTranslationKey(
				`question${question.questionId}`,
				question.text,
				'en'
			);
			this.transloco.setTranslationKey(
				`question${question.questionId}`,
				question.textSpanish,
				'es'
			);
			this.transloco.setTranslationKey(
				`question${question.questionId}Description`,
				question.description,
				'en'
			);
			this.transloco.setTranslationKey(
				`question${question.questionId}Description`,
				question.descriptionSpanish,
				'es'
			);
			question.options.forEach((option, optionIndex) => {
				this.transloco.setTranslationKey(
					`question${question.questionId}Option${optionIndex}`,
					option.text,
					'en'
				);
				this.transloco.setTranslationKey(
					`question${question.questionId}Option${optionIndex}`,
					option.spanish,
					'es'
				);
			});
		});
	}

	export(downloadResults) {
		const csvColumns = [
			{ prop: 'type', name: 'Type' },
			{ prop: 'fullName', name: 'Entrant' },
			{ prop: 'screener', name: 'Screener' },
			{ prop: 'entryDateTime', name: 'Entry Date', type: 'date' },
			{ prop: 'entryDateTime', name: 'Entry Time', type: 'time' },
			{ prop: 'signatureExport', name: 'Signature' },
			{ prop: 'temp1', name: 'First Temperature' },
			{
				prop: 'temp1DateTime',
				name: 'First Temperature Time',
				type: 'time',
			},
			{ prop: 'temp2', name: 'Second Temperature' },
			{
				prop: 'temp2DateTime',
				name: 'Second Temperature Time',
				type: 'time',
			},
			{ prop: 'note', name: 'Note' },
		];

		let csv = `${csvColumns.map((col) => col.name).join(',')},`;

		const allQuestions = this.screeningLogSource.data.reduce(
			(uQuestions, logEntry) => {
				logEntry.questions.forEach((question) => {
					uQuestions.add(question.exportHeader ?? question.text);
				});
				return uQuestions;
			},
			new Set<string>()
		);

		csv += `${[...allQuestions].join(',')}\n`;

		csv += this.screeningLogSource.data
			.map((logEntry) => {
				let ret = `${csvColumns
					.map((col) => {
						const value = logEntry[col.prop] ?? '';
						if (!value) {
							return '';
						}
						switch (col.type) {
							case 'date':
								return `"${format(parseISO(value), 'P')}"`;
							case 'time':
								return `"${format(parseISO(value), 'p')}"`;
							default:
								return `"${value || ''}"`;
						}
					})
					.join(',')},`;
				ret += [...allQuestions]
					.map((exportHeader) => {
						const logQuestion = logEntry.questions.find((q) => q.exportHeader === exportHeader ||
								q.text === exportHeader);
						return `"${logQuestion?.selectedAnswer || ''}"`;
					})
					.join(',');
				return ret;
			})
			.join('\n');

		const csvBlob = new Blob(['\ufeff', csv], { type: 'text/csv' });

		downloadResults.setAttribute('href', URL.createObjectURL(csvBlob));
	}

	restoreDefaultQuestions() {
		// TODO update this
		// return listVal<FacilityQuestion>(ref(this.db, 'commonCMSQuestions'))
		// 	.pipe(first())
		// 	.subscribe((defaults) => {
		// 		this.facility.questions = defaults.map((q) => new FacilityQuestion(q));
		// 		this.saveQuestions();
		// 	});
	}

	removeUser(user: FacilityUser) {
		const updates: Record<string, any> = [];
		updates[`users.${user.userId}`] = deleteField();
		return from(updateDoc(doc(this.firestore, `facilities/${this.facility.facilityId}`).withConverter(FacilityConvertor), updates));
	}

	private uploadSignatureImage(
		signature: string,
		path: string
	): Observable<string> {
		if (!signature) {
			return of('');
		}
		return from(uploadString(storageRef(this.storage, path), signature, 'data_url')).pipe(switchMap((result) => getDownloadURL(result.ref)));
	}
}
