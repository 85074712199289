import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AlertConfig } from '../alert/alert.dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'ss-confirm',
	templateUrl: './confirm.dialog.html',
	styleUrls: ['./confirm.dialog.scss'],
	standalone: true,
	imports: [MatDialogModule, MatButtonModule],
})
export class ConfirmDialog implements OnInit {

	defaultConfig: Partial<AlertConfig> = {
		closeLabel: 'No',
		confirmLabel: 'Yes',
		type: 'success',
		reverseButtons: false,
		leftAlign: false
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: AlertConfig) {}

	ngOnInit() {
		this.data = Object.assign(this.defaultConfig, this.data);
	}
}
