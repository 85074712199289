import { computed, inject, Injectable, signal } from '@angular/core';
import { Analytics } from '@angular/fire/analytics';
import {
	Auth,
	authState,
	EmailAuthProvider,
	multiFactor,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	reauthenticateWithCredential,
	RecaptchaVerifier,
	updatePassword,
	User
} from '@angular/fire/auth';
import {
	collection,
	CollectionReference,
	doc,
	docData,
	DocumentReference,
	Firestore,
	updateDoc
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Facility, MyUserInfo, UserNotificationSetting } from '@ss/typings/facility';
import { setUserProperties } from 'firebase/analytics';
import { BehaviorSubject, combineLatest, firstValueFrom, from, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserStore {

	user = signal<User>(null);

	userInfo = signal(new MyUserInfo({}));

	analytics = inject(Analytics);

	userLoaded: Observable<User>;

	private subject = new BehaviorSubject<MyUserInfo>(null);

	loaded = this.subject.asObservable().pipe(filter((u) => !!u));

	proxying = computed(() => !!this.userInfo().proxyFacilityId);

	hasMultiFacilities = computed(() => this.userInfo().facilities?.length > 1);

	canViewEnterprise = computed(() => this.userInfo().isSuperAdmin || this.userInfo().enterpriseId || this.userInfo().enterpriseIds?.length > 0);

	constructor(
		private auth: Auth,
		private firestore: Firestore,
		private router: Router,
	) {
		this.userLoaded = authState(this.auth)
			.pipe(
				tap((user) => {
					this.user.set(user);
				}),
				shareReplay(1)
			);
		this.userLoaded.pipe(
			tap((user) => {
				if (!user) {
					this.subject.next(this.userInfo());
					this.userInfo.set(new MyUserInfo({}));
				}
			}),
			filter((user) => !!user),
			map((user) => user.uid),
			switchMap((uid) => docData<MyUserInfo>(doc(this.firestore, '/users', uid) as DocumentReference<MyUserInfo>, { idField: 'userId' })),
			switchMap((userFacilityInfo: MyUserInfo) => {
				setUserProperties(this.analytics, {
					enterpriseId: userFacilityInfo.enterpriseId || 'none',
					isSuperAdmin: userFacilityInfo.isSuperAdmin ?? false,
					facilityId: userFacilityInfo.currentFacilityId || 'none'
				});
				this.userInfo.set({
					email: this.user()?.email,
					name: this.user()?.displayName,
					phoneNumber: this.user()?.phoneNumber,
					...userFacilityInfo,
				});
				if (userFacilityInfo?.facilityIds?.length === 0) {
					return of([]);
				}
				return combineLatest(userFacilityInfo?.facilityIds.map((facilityId) => {
					const facilityDoc = doc(this.firestore, 'facilities', facilityId) as DocumentReference<Facility>;
					return docData<Facility>(facilityDoc, { idField: 'facilityId' });
				}));
			})
		).subscribe((facilities) => {
			this.userInfo.update((u) => {
				u.facility = u.facilities?.find((f) => f.facilityId === u.currentFacilityId);
				u.facilities = facilities.filter((f) => f).map((f) => ({ name: f.name, facilityId: f.facilityId, ntaEnabled: f.ntaEnabled }));
				return u;
			});
			this.subject.next(this.userInfo());
		});
	}

	reauthenticate(username: string, password: string) {
		const credential = EmailAuthProvider.credential(username, password);

		return reauthenticateWithCredential(this.user(), credential);
	}

	async saveUserInfo(userUpdate: { name: string; email: string }) {
		await updateDoc(doc(this.firestore, `/users/${this.user().uid}`), { ...userUpdate });
	}

	async saveNotificationSettings(notificationSettings: UserNotificationSetting[]) {
		notificationSettings = notificationSettings.map((n) => Object.keys(n).reduce((acc, key) => {
			if (n[key] !== undefined) {
				acc[key] = n[key];
			}
			return acc;
		}, {} satisfies UserNotificationSetting));
		await updateDoc(doc(this.firestore, `/users/${this.user().uid}`), {
			notificationSettings: notificationSettings.map((n) => Object.keys(n).reduce((acc, key) => {
				if (n[key] !== undefined) {
					acc[key] = n[key];
				}
				return acc;
			}, {}))
		});
	}

	updateFacility(facilityId) {
		return from(updateDoc(doc(this.firestore, `/users/${this.user().uid}`), { currentFacilityId: facilityId }));
	}

	changeProxyAccess(facilityId?: number) {
		updateDoc(doc(this.firestore, `users/${this.user().uid}`), { proxyFacilityId: facilityId ?? null }).then(() => this.router.navigate(['']));
	}

	async updateTempPassword(newPassword: string, currentPassword: string) {
		await this.reauthenticate(this.user().email, currentPassword);
		await updatePassword(this.user(), newPassword);
		await updateDoc(doc(this.firestore, `/users/${this.user().uid}`), { hasTempPassword: false });
	}

	async sendCode(password: string, phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) {
		await firstValueFrom(this.userLoaded);
		await this.reauthenticate(this.user().email, password);
		const session = await multiFactor(this.user()).getSession();
		const phoneInfoOptions = { phoneNumber, session };
		const phoneAuthProvider = new PhoneAuthProvider(this.auth);
		const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
		return verificationId;
	}

	validateCode(verificationId: string, verificationCode: string) {
		const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
		const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

		return multiFactorAssertion;
	}

	async enableMfa(verificationId: string, verificationCode: string) {
		const multiFactorAssertion = this.validateCode(verificationId, verificationCode);

		await multiFactor(this.user()).enroll(multiFactorAssertion);

		return updateDoc(doc(this.firestore, `/users/${this.user().uid}`), { mfaEnabled: true });
	}
}
