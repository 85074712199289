import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	constructor(private snackBar: MatSnackBar) {}

	public failure(message: string) {
		this.snackBar.openFromComponent(SnackbarComponent, {
			duration: 3000,
			horizontalPosition: 'end',
			verticalPosition: 'top',
			data: {
				type: 'failure',
				message,
			},
		});
	}

	public success(message: string, duration = 3000) {
		this.snackBar.openFromComponent(SnackbarComponent, {
			duration,
			horizontalPosition: 'end',
			verticalPosition: 'top',
			data: {
				type: 'success',
				message,
			},
		});
	}
}
