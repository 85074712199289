import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

export class SnackBarData {
	type: 'failure' | 'success';

	message: string;
}

@Component({
	selector: 'ss-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
	standalone: true,
	imports: [MatIconModule],
})
export class SnackbarComponent implements OnInit {
	fontSet: string;

	fontIcon: string;

	private successIcons = [
		{ set: 'fas', name: 'fa-thumbs-up' },
		{ set: 'fab', name: 'fa-rebel' },
		{ set: 'fas', name: 'fa-medal' },
	];

	private failureIcons = [
		{ set: 'fas', name: 'fa-exclamation-circle' },
		{ set: 'fab', name: 'fa-first-order' },
		{ set: 'fas', name: 'fa-car-crash' },
	];

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

	ngOnInit(): void {
		let icon;
		if (this.data.type === 'failure') {
			icon =
				this.failureIcons[
					Math.floor(Math.random() * this.failureIcons.length)
				];
		} else {
			icon =
				this.successIcons[
					Math.floor(Math.random() * this.successIcons.length)
				];
		}
		this.fontSet = icon.set;
		this.fontIcon = icon.name;
	}
}
