import { getSymbols } from '../compat/_internal/getSymbols.mjs';
import { isPrimitive } from '../predicate/isPrimitive.mjs';
import { isTypedArray } from '../predicate/isTypedArray.mjs';

function cloneDeep(obj) {
    return cloneDeepImpl(obj);
}
function cloneDeepImpl(obj, stack = new Map()) {
    if (isPrimitive(obj)) {
        return obj;
    }
    if (stack.has(obj)) {
        return stack.get(obj);
    }
    if (Array.isArray(obj)) {
        const result = new Array(obj.length);
        stack.set(obj, result);
        for (let i = 0; i < obj.length; i++) {
            result[i] = cloneDeepImpl(obj[i], stack);
        }
        if (Object.hasOwn(obj, 'index')) {
            result.index = obj.index;
        }
        if (Object.hasOwn(obj, 'input')) {
            result.input = obj.input;
        }
        return result;
    }
    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }
    if (obj instanceof RegExp) {
        const result = new RegExp(obj.source, obj.flags);
        result.lastIndex = obj.lastIndex;
        return result;
    }
    if (obj instanceof Map) {
        const result = new Map();
        stack.set(obj, result);
        for (const [key, value] of obj) {
            result.set(key, cloneDeepImpl(value, stack));
        }
        return result;
    }
    if (obj instanceof Set) {
        const result = new Set();
        stack.set(obj, result);
        for (const value of obj) {
            result.add(cloneDeepImpl(value, stack));
        }
        return result;
    }
    if (typeof Buffer !== 'undefined' && Buffer.isBuffer(obj)) {
        return obj.subarray();
    }
    if (isTypedArray(obj)) {
        const result = new (Object.getPrototypeOf(obj).constructor)(obj.length);
        stack.set(obj, result);
        for (let i = 0; i < obj.length; i++) {
            result[i] = cloneDeepImpl(obj[i], stack);
        }
        return result;
    }
    if (obj instanceof ArrayBuffer || (typeof SharedArrayBuffer !== 'undefined' && obj instanceof SharedArrayBuffer)) {
        return obj.slice(0);
    }
    if (obj instanceof DataView) {
        const result = new DataView(obj.buffer.slice(0), obj.byteOffset, obj.byteLength);
        stack.set(obj, result);
        copyProperties(result, obj, stack);
        return result;
    }
    if (typeof File !== 'undefined' && obj instanceof File) {
        const result = new File([obj], obj.name, { type: obj.type });
        stack.set(obj, result);
        copyProperties(result, obj, stack);
        return result;
    }
    if (obj instanceof Blob) {
        const result = new Blob([obj], { type: obj.type });
        stack.set(obj, result);
        copyProperties(result, obj, stack);
        return result;
    }
    if (obj instanceof Error) {
        const result = new obj.constructor();
        stack.set(obj, result);
        result.message = obj.message;
        result.name = obj.name;
        result.stack = obj.stack;
        result.cause = obj.cause;
        copyProperties(result, obj, stack);
        return result;
    }
    if (typeof obj === 'object' && obj !== null) {
        const result = {};
        stack.set(obj, result);
        copyProperties(result, obj, stack);
        return result;
    }
    return obj;
}
function copyProperties(target, source, stack) {
    const keys = [...Object.keys(source), ...getSymbols(source)];
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const descriptor = Object.getOwnPropertyDescriptor(target, key);
        if (descriptor == null || descriptor.writable) {
            target[key] = cloneDeepImpl(source[key], stack);
        }
    }
}

export { cloneDeep, copyProperties };
