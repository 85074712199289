import { Component, HostBinding, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

export class AlertConfig {
	title: string;

	message: string;

	fontSet?: string;

	fontIcon?: string;

	closeLabel?: string | boolean;

	confirmLabel?: string | boolean;

	reverseButtons?: boolean;

	leftAlign?: boolean;

	type: 'success' | 'warning' | 'confirm';

	sound?: HTMLAudioElement;
}

@Component({
	selector: 'ss-alert',
	templateUrl: './alert.dialog.html',
	styleUrls: ['./alert.dialog.scss'],
	standalone: true,
	imports: [MatDialogModule, NgIf, MatIconModule, MatButtonModule]
})
export class AlertDialog implements OnInit {
	defaultConfig: Partial<AlertConfig> = {
		closeLabel: 'close',
		type: 'success',
		reverseButtons: false,
		leftAlign: false
	};

	data: AlertConfig = inject(MAT_DIALOG_DATA);

	@HostBinding('class') get class() {
		return this.data.type;
	}

	ngOnInit() {
		this.data = Object.assign(this.defaultConfig, this.data);
		if (this.data.sound) {
			this.data.sound.play();
		}
	}

}
