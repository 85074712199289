<mat-dialog-content>
	<h2>{{data.title}}</h2>
	@if (data.fontSet) {
		<mat-icon [fontSet]="data.fontSet" [fontIcon]="data.fontIcon"></mat-icon>
	} @else {
		<mat-icon>{{data.fontIcon}}</mat-icon>
	}
	<div class="body" [class.left-align]="data.leftAlign" [innerHtml]="data.message"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" [class.reverse]="data.reverseButtons" class="gap-4 px-4">
	@if (data.closeLabel) {
		<button mat-button mat-dialog-close>{{data.closeLabel}}</button>
	}
	@if (data.confirmLabel) {
		<button mat-button [mat-dialog-close]="true">{{data.confirmLabel}}</button>
	}
</mat-dialog-actions>
