import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FacilityStore } from './facility.store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class PermissionGuard {
	constructor(
		private userStore: UserStore,
		private facilityStore: FacilityStore,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const prop = route.data.prop as string;
		const redirect = (route.data.redirect as string) || '';
		return this.userStore.loaded.pipe(
			switchMap((u) => {
				if (!u) {
					return throwError(() => new Error('not signed in'));
				}
				return this.facilityStore.load();
			}),
			switchMap(() => {
				if (!this.facilityStore[prop]) {
					return throwError(() => new Error('invalid permissions'));
				}
				return of(true);
			}),
			catchError(() => {
				this.router.navigate([redirect]);
				return of(false);
			})
		);
	}
}
